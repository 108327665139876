import { Search } from 'auto-libs'
import { Button, Layout, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FeedBack from '../../components/feedback'
import http from '../../utils/http'
import { getUserId, setIQStartTime, setUserId } from '../../utils/storage'
import Banner1 from './imgs/1.jpeg'
import Banner2 from './imgs/2.jpeg'
import Banner from './imgs/banner.jpeg'
import './style.scss'

const PageIndex = () => {
  const navigateHook = useNavigate()
  const channel = Search.get('channel') || ''
  const [orderNo, setOrderNo] = useState('')

  const onSubmit = async () => {
    try {
      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          productType: '2', // 1:姓名匹对，2：智商测试
          channel,
        },
      })

      const { code, body } = result
      if (code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      setIQStartTime(String(new Date().getTime()))

      setOrderNo(body.orderId)

      navigateHook('/qa', { state: { orderId: body.orderId } })
    } catch (e) {
      Toast('请求失败，请再试')
    }
  }

  const fetchUserId = async () => {
    try {
      const result: any = await http.request({
        url: '/user/getNewUserId',
        method: 'POST',
        data: {},
      })

      const { code, body } = result

      if (code !== '00000000') {
        return
      }

      const { userId } = body

      setUserId(userId)
      statistics(userId)
    } catch (error) {}
  }

  const statistics = async (userId: string) => {
    const params: any = { userId }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  useEffect(() => {
    const userId = getUserId()
    if (userId) {
      statistics(userId)
      return
    }

    fetchUserId()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="banner">
          <img className="img" src={Banner} alt="" />
        </div>

        <div className="body">
          <h1>瑞文标准智力测评：你有多聪明？</h1>

          <p className="p20">
            瑞文标准推理测验（Ravens Standard Progressive Matrices
            简称SPM）由英国心理学家瑞文（J.C.Raven）于1938年创制，在世界各国沿用，用以测验一个人的观察力及清晰思维的能力。它是一种纯粹的非文字智力测验，所以广泛应用于无国界的智力/推理能力测试，属于渐近性矩阵图，整个测验一共有60张图组成，由5个单元的渐进矩阵构图组成，每个单元在智慧活动的要求上各不相同，总的来说，矩阵的结构越来越复杂，从一个层次到多个层次的演变，要求的思维操作也是从直接观察到间接抽象推理的渐进过程
          </p>
          <p className="p20">
            包括瑞文标准推理和瑞文高级推理两类，这些测试都具有跨语言、跨文化应用的优势，是国际上非常流行的智力水平测查量表之一。ATS提供的这个版本为瑞文高级推理测验的改编版本(Hannon，2002)，结合了最先进的项目反应理论，综合考虑了测验过程中答题者的作答情况，能够更准确地衡量智力水平。
          </p>

          <p className="p20">
            本测验通过五个方面(反映知觉辨别能力、反映类同比较能力、反映比较推理能力、反映系列关系能力、反映抽象推理能力)的得分结构，能一定程度上有助于了解被测者智力结构。智力测试与多种因素有关,波动范围大约在3到10左右，经常做智力游戏题的人，在各种智力测试中的分数都有可能提供,本智力测试分数不能代表未来成就大小。如果您需要了解更加详细的分析,可以查看我们对您本次测试的深度报告。
          </p>
          <p>
            <img className="img" src={Banner1} />
            <img className="img" src={Banner2} />
          </p>

          <div className="tip">
            <h2>测评须知：</h2>
            <p>1. 请在心态平和及时间充足的情况下才开始答题。</p>
            <p>
              2.请仔细阅读题目，选择最合适的图形填充到缺省的部分，测试结果会分别从五个维度给出分值！每个纬度的满分12分。
            </p>
            <p>
              3.测验一般没有时间限制，一般在40分钟左右完成，答对的总分转化为百分等级。在个别测验时，如果记录下测试所用时间，并分析其错误的特性，还可以有助于了解被试者的气质，性格和情绪等方面的特点。
            </p>
            <p>4.本测试可免费作答，测评结果需要付费阅读，测评结果会以报告形式呈现。</p>
          </div>

          <div className="company">上海简水网络科技有限公司</div>
        </div>

        <FeedBack orderNo={orderNo} />
      </Layout.Body>

      <Layout.Footer className="footer">
        <div className="btns">
          <Button className="submit" onClick={onSubmit}>
            开始测试
          </Button>
        </div>
      </Layout.Footer>
    </Layout>
  )
}

export default PageIndex
