import Cookies from 'js-cookie'
import getUUID from './uuid'

export const getUserId = () => Cookies.get('__app__userId__')
export const setUserId = (value: string) => {
  Cookies.set('__app__userId__', value, { domain: 'ijianshui.com', path: '', expires: 365 })
}

export const getOrderNo = () => sessionStorage['__app__iq__orderNo__']
export const setOrderNo = (value: string) => {
  sessionStorage['__app__iq__orderNo__'] = value
}

export const getIQStartTime = () => sessionStorage['__app__iq__start__time__']
export const setIQStartTime = (value: string) => {
  sessionStorage['__app__iq__start__time__'] = value
}

export const setStorageUUID = (value: string) => {
  localStorage['__app__iq__uuid__'] = value
}

export const getStorageUUID = () => {
  let ls = localStorage['__app__iq__uuid__']
  if (!ls) {
    ls = getUUID()
    setStorageUUID(ls)
  }

  return ls
}
