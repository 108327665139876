import { Alert, Button, Layout, Toast } from 'auto-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { qaList, rightAnswer } from './data'
import './style.scss'
import { IQA, IState } from './types'

const PageQA = () => {
  const navigateHook = useNavigate()
  const location: any = useLocation()
  const orderId = location.state.orderId
  const [state, setState] = useSetState<IState>({ current: 1, myAnswers: [] })
  const currentQA: IQA = qaList[state.current]

  const onPrev = () => {
    setState({
      current: state.current - 1,
    })
  }

  const onNext = () => {
    const isFinished = onCheckedCurrentIsFinished()

    if (!isFinished) {
      Toast('请完成当前题目')
      return
    }

    setState({
      current: state.current + 1,
    })
  }

  const onCheckedCurrentIsFinished = (): boolean => {
    const { myAnswers } = state
    const current = state.current - 1

    if (!myAnswers[current]) {
      return false
    }

    return true
  }

  const onAnswerClick = (value: never) => {
    const { myAnswers } = state
    const current = state.current - 1
    const ans: Array<number> = [...myAnswers]

    if (myAnswers[current]) {
      ans.splice(current, 1, value)
    } else {
      ans.push(value)
    }

    setState({
      myAnswers: ans,
      current: state.current + 1 > 60 ? 60 : state.current + 1,
    })

    if (state.current === 60) {
      Alert({
        title: '恭喜您，您的测评已完成',
        btns: [
          {
            name: '确认信息查看结果',
            onClick: () => {
              navigateHook('/info', { state: { score: getScore(), orderId } })
            },
          },
        ],
      })
    }
  }

  const onBack = () => {
    navigateHook(-1)
  }

  const getScore = () => {
    let score = 0
    for (let i = 0; i < 60; i++) {
      if (state.myAnswers[i] === rightAnswer[i]) {
        score++
      }
    }

    return score
  }

  const onFinished = () => {
    const isFinished = onCheckedCurrentIsFinished()

    if (!isFinished) {
      Toast('请完成当前题目')
      return
    }

    navigateHook('/info', { state: { score: getScore(), orderId } })
  }

  return (
    <Layout className="page-qa" useBottomSafeArea={false}>
      <Layout.Body className="body">
        <h1>瑞文标准智力测评</h1>
        <div className="process">
          <div className="bar" style={{ width: `${(state.current / 60) * 100}%` }} />
          <div className="total" style={{ left: `${(state.current / 60) * 100}%` }}>
            {state.current}/60
          </div>
        </div>

        <div className="qa">
          <div className="title">
            {state.current}.{currentQA.title}
          </div>
          <div className="question">
            <img src={currentQA.question} />
          </div>

          <div className="answers">
            {currentQA.answers.map((item, index) => (
              <div
                className={`answer ${
                  state.myAnswers[state.current - 1] === index + 1 ? 'checked' : ''
                }`}
                onClick={() => onAnswerClick((index + 1) as never)}
                key={item}
              >
                <img src={item} />
              </div>
            ))}
          </div>

          <div className="btns">
            {state.current === 1 ? (
              <Button type="secondary" className="btn" shrink onClick={onBack}>
                返回
              </Button>
            ) : (
              <Button type="secondary" className="btn" shrink onClick={onPrev}>
                上一题
              </Button>
            )}

            {state.current === 60 ? (
              <Button type="secondary" className="btn" shrink onClick={onFinished}>
                查看测试结果
              </Button>
            ) : (
              <Button type="secondary" className="btn" shrink onClick={onNext}>
                下一题
              </Button>
            )}
          </div>
        </div>

        <div className="tip">
          <p>答题须知：</p>
          <p>1. 请在心态平和及时间充足的情况下才开始答题。</p>
          <p>
            2.请仔细阅读题目，选择最合适的图形填充到缺省的部分，测试结果会分别从五个维度给出分值！每个纬度的满分12分。
          </p>
          <p>
            3.测验一般没有时间限制，一般在40分钟左右完成，答对的总分转化为百分等级。在个别测验时，如果记录下测试所用时间，并分析其错误的特性，还可以有助于了解被试者的气质，性格和情绪等方面的特点。
          </p>
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default PageQA
