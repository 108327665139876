import { Search } from 'auto-libs'
import { Alert, Layout, Loading, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import FeedBack from '../../components/feedback'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import data from './data'
import IQBanner from './imgs/iq.jpeg'
import TipPng from './imgs/tip.png'
import './style.scss'

const PageIndex = () => {
  const navigateHook = useNavigate()
  const orderId = Search.get('orderNo') || ''

  // 默认4级
  const [state, setState] = useSetState({
    alipayForm: '',
    orderStatus: false,
    result: data.l4,
    level: '4',
    IQ: 100,
    loading: false,
  })

  let payWay = 'ALIPAY'

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { status, payMethod, level = 3 } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result = (data as any)[`l${level}`]
      const [min, max] = result.IQRange

      setState({
        level,
        result,
        orderStatus: status === 2 ? true : false,
        IQ: getRandomIQ(min, max),
      })
    } catch (e) {
      Toast('系统异常，请重试')
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const getRandomIQ = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min)) + min + 1
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId, state.level)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId, state.level)

    setState({
      alipayForm: result,
    })

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })
  }

  useEffect(() => {
    fetchMatchResult()
  }, [])

  return (
    <Layout className="page-result">
      <Layout.Header
        title="智商IQ测试报告"
        onBackClick={() => {
          navigateHook('/', { replace: true })
        }}
      />

      <Layout.Body className="body" loading={state.loading}>
        {!state.orderStatus ? (
          <div className="nopay">
            <div className="title">
              您的<em>瑞文标准智力测评</em>报告已经生成，支付完成后将自动进入报告查看页面。
            </div>

            <div className="tip-price">
              <p> 测评报告已生成 </p>
              <p className="price">
                解锁价格: <em>29.90元</em>
              </p>

              <p className="zan">
                <i /> 98.66%的测试过的人觉得很准
              </p>

              <p> 付费后，您的专业测评报告将安全的永久保存，供您随时查看。 </p>
            </div>

            <div className="desc">
              <img src={TipPng} />
            </div>
          </div>
        ) : (
          <div className="pay">
            <div className="title">
              注：本次测验共60题，参考您本次得分计算出您的瑞文标准分,再计算出您的IQ值，智力等级分为7级。测评结果仅供参考，不作为专业建议
            </div>
            <div className="item subscribe">
              <div className="subtitle">总体概述</div>
              <div className="content">
                <p>您好！您本次测试结构数据如下:</p>
                <p>
                  您的瑞文原始得分：<em>{state.result?.rwbz}</em>
                </p>
                <p>
                  您的IQ值： <em>{state.IQ}</em>
                </p>
                <p>
                  您的智力等级为:<em>{state.result?.level}</em>
                </p>
                <p>
                  智力说明: <em>{state.result?.levelDesc}</em>
                </p>
              </div>
            </div>
            <div className="item">
              <div className="subtitle">结果分析</div>
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: state.result?.desc }} />
              </div>
            </div>
            <div className="item">
              <div className="subtitle">维度分析</div>
              <div className="content">
                <p>
                  <img src={IQBanner} />
                </p>
                <p>智商为100左右的人约占35%，智商130以上者也只有3%，智商低于70的人也不到3%。</p>
                <p>
                  也就是说，世界上的大多数人都是智商差不多的普通人，有着相同的起点，成功也需要后天的努力。
                </p>
                <p>智力构成的5个因素</p>
                <p className="b">1、观察力</p>
                <p>
                  是指大脑对事物的观察能力，如通过观察发现新奇的事物等，在观察过程对声音、气味、温度等有一个新的认识，并通过对现象的观察，提高对事物本质认识的能力。我们可以在学习训练中增加一些训练内容如观察和想像项目，通过训练来提高学员的观察力和想像力。
                </p>
                <p className="b">2、注意力</p>
                <p>
                  是指人的心理活动指向和集中于某种事物的能力。如我们好的学员能全神贯注地长时间地看书和研究课题等，而对其他无关游戏、活动等的兴趣大大降低，这就是注意力强的体现。
                </p>
                <p className="b">3、记忆力</p>
                <p>
                  是识记、保持、再认识和重现客观事物所反映的内容和经验的能力。例如我们到老时也还记得父亲母亲年轻时的形象，少年时家庭的环境等一些场景，那就是人的记忆在起作用。
                </p>
                <p className="b">4、思维力</p>
                <p>
                  是人脑对客观事物间接的、概括的反映能力。当人们在学会观察事物之后，他逐渐会把各种不同的物品、事件、经验分类归纳，不同的类型他都能通过思维进行概括。思维力是智力的核心。
                </p>
                <p className="b">5、想象力</p>
                <p>
                  是人在已有形象的基础上，在头脑中创造出新形象的能力。比如当你说起汽车，我马上就想象出各种各样的汽车形象来就是这个道理。因此，想象一般是在掌握一定的知识面的基础上完成的。
                </p>
              </div>
            </div>
            <div className="item">
              <div className="subtitle">专家建议</div>
              <div className="content">
                <p className="b">1、广泛的涉猎和阅读</p>
                <p>
                  除了文化课本外，其他方面的知识也要想办法和猎取，那样，你的知识面才能足够的开阔，那样在处理事情的时候才能够全面和分析出有效的解决方法。
                </p>
                <p className="b">2、平时注重学习方法的积累和学习参考</p>
                <p>
                  一个人的能力是有限的，像智商高的学习能力强的人学习是一种最有效的提升智商情商的方法，百试不爽。
                </p>
                <p className="b">3、学习知识的限制是必要的</p>
                <p>
                  要想成为高智商的人，就要看高智商的书籍，正如有了一个伟大的老师，你才有可能成为一个伟大的人一样，如果你看的书籍不够高智商，那么，会事得其反，得不偿失。
                </p>
                <p className="b">4、与高智商的人切磋</p>
                <p>
                  通过与高智商的人切磋，那么，你才能等到真正的锻炼和提高，才能快速的提高你自己的智商。
                </p>
                <p className="b">5、对自己要求适当严一点</p>
                <p>
                  与别人比，不如与自己比，每次给自己一个适当的高要求，每次提升一下自己，那么，你的进步是看得见的，也是可观的。
                </p>
                <p className="b">6、驱动你的大脑细胞</p>
                <p>
                  研究表明得到足够运动的人，其大脑也更好。加州拉由拉市的萨克生物研究学院的科学家发现，与整天坐那儿在网络聊天室里讨论指环王的老鼠相比，只要觉得想要跑步就在转轮上跑动的成年老鼠的海马得到的新细胞是他们的两倍，海马是大脑控制学习和记忆的部分。研究者们也不能确定为什么更活跃的啮齿动物的大脑会有这种反应，但可以知道的是这种自愿的运动可以减压，因此而更有益。这意味着找到了享受运动，而不是强迫自己去运动的方法，会让你更聪明，也更有幸福感。
                </p>
                <p>
                  所以，做点运动，选择一个训练项目比如马拉松，三项全能或者“趣味赛跑”，或者找个伴儿一起让运动变得有趣。
                </p>
                <p className="b">7、锻炼你的思维</p>
                <p>
                  让你的大脑细胞活跃起来并不只是物理运动。就像那些出租车司机和玩儿钢琴的人，你可以通过使用大脑里各种各样的区域来建立它们。杜克大学神经生物学教授罗伦斯·C·凯兹，也是《让你大脑New一下》的合著者，找到使用大脑退化部分的简单途径不仅有助于维护神经细胞，也有助于细胞上的树突和轴突接收传递信息。凯兹说就像一次新的举重练习帮助建立未充分使用的肌肉，用新奇的方式思考和观察世界有助于改善大脑不活跃部分的功能。
                </p>
                <p>
                  体验新的味觉和嗅觉；用你平时不用的那只手做事；找些新的路开车上班；到新的地方去旅行；进行艺术创作；读读陀思妥耶夫斯基的小说；为泰德·肯尼迪和拉什·林宝写一篇兄弟喜剧——基本上，做一切可以让你的大脑跳出常轨的事。
                </p>
                <p className="b">8、多问为什么</p>
                <p>
                  我们的大脑与好奇心联系在一起。随着我们长大变“成熟”，许多人开始抑制或否认自己天生的好奇心。让你充满好奇心吧！问问自己为什么会发生这些事情。问问知道的人们。锻炼好奇心最好的方式就是问“为什么？”让一天至少问十个“为什么”成为你的习惯吧。你的大脑会变的愉快，你也会惊讶地发现你的生活工作中有那么多的机遇和解决方案。
                </p>
                <p className="b">9、笑</p>
                <p>
                  科学家告诉我们笑有益健康；它可以促进体内释放内啡肽及其它有积极力量的化学物质。事实上我并不需要科学家来告诉我们笑会带来良好感觉。笑可以帮助我们减压，还可以打破旧有模式。因此笑可以看做是对大脑的快速充电。多多大笑吧！
                </p>
                <p className="b">10、当一个鱼头</p>
                <p>
                  欧米咖-3油是在胡桃、亚麻仁尤其是鱼体内发现的，很长一段时间都作为有益心脏的保健品销售。但是最近的研究表明，它也是脑力提升者，这不仅仅在于它帮助循环系统向大脑输送氧气。它还可以改善大脑细胞的细胞膜功能，也许这可以解释为什么吃了很多鱼的人比较不容易有抑郁，智力衰退，甚至注意力紊乱的问题。科学家已经注意到脂肪酸对于儿童大脑正常发育是必须的，而且已经把它加入婴儿饮食配方中。你的脑力，甚至你的智力有可能因为摄入足够的这种油而提高。
                </p>
                <p> 一周至少吃三次鱼，比如大马哈鱼，沙丁鱼，鲭鱼和金枪鱼，这是一个好的开始。 </p>
                <p className="b"> 11、记忆</p>
                <p>
                  找出一个旧相册或者高中同学录。你的大脑是一部记忆机器，所以给它工作的机会吧！花点时间给记忆。让你的思维自我反应，它将带给你积极的情绪，与记忆的新联系有助于你当前的工作和挑战。
                </p>
                <p className="b"> 12、减肥</p>
                <p>
                  讨厌的脂肪是否让你变得愚笨？多伦多大学的研究者发现，让老鼠们食用含有40%脂肪的食物，它们在一些方面的思维功能就减弱了，包括记忆、空间识别以及规则学习。当饮食中的充满脂肪，也就是充满肉奶组成的食物时，情况变得更糟。你也许从未在迷宫中找过干奶酪块，这些结果还是适用于你，这有两个原因：脂肪会减少富氧血液流向大脑，同时还降低葡萄糖的新陈代谢，糖是大脑的食物。
                </p>
                <p>
                  每天30%的卡路里你仍然可以通过脂肪摄入，但是其余大部分应该从前面提到的鱼，橄榄油，坚果和种子中摄取。无论你做什么，都要远离肥胖，那些饼干零食中充满了硬化油。
                </p>
                <p className="b">13、做个猜谜游戏</p>
                <p>
                  有些人喜欢拼图游戏，纵横字谜游戏，逻辑拼图——你选择那种游戏不要紧。在空闲时间做些猜谜游戏是活动大脑很棒的的方法，并且使你的大脑保持良好的工作状态。做点猜谜找点乐子，但一定要在知道你在锻炼大脑的情况下进行。
                </p>
                <p className="b">14、莫扎特效应</p>
                <p>
                  十几年前，现在威斯康辛州威斯康辛大学的心理学家弗朗西丝·劳希尔和她的同事发现听莫扎特可以提高人们数学和空间推理能力。即使老鼠走迷宫，听了莫扎特音乐也比听完白噪声或极简主义作曲家菲利普·格拉斯的音乐后走的更快更精确。去年，劳希尔报告说，至少对于老鼠来说，一曲莫扎特奏鸣曲可以刺激与大脑中神经细胞信号有关的三个基因的活动。
                </p>
                <p>
                  这听起来就像是调整心智机能的最和谐的方式。但是在你拿起CD之前，看看需要小心什么。不是每一个期待莫扎特效应的人都找到了它。再说，即使它的支持者也认为音乐提升脑力不过是因为它让听者感觉更好——同时感到放松和兴奋——相似的刺激也可以做到。事实上，一个研究发现听一个故事也能带来相似的提升。
                </p>
                <p className="b">15、改善你做过的事情的技能</p>
                <p>
                  只要你想拓展你的能力和知识，一些重复的脑力刺激也未尝不可。一般的行动如种植，缝纫，打桥牌，读书，绘画，和做纵横字谜游戏也都是有价值的，但你要让自己尝试不同的园艺技术，进行更复杂的缝纫方式，和更天才的对手打桥牌来增长自己的技能，读一些新各个领域的新作者的文章，学习一种新的绘画技巧，做更难的纵横字谜游戏。把你的大脑推向新的高度以使它健康。
                </p>
                <p className="b">16、做一名思考者而不是酗酒者</p>
                <p>
                  酒精杀死脑细胞已经是众所周知的了，但事实要更复杂一点。事实上，一个关于3500名日本人的调查发现，当他们变老时，那些适度喝酒的人（在这个案例中是大约一天喝一次）比一点也不饮酒的人有着更好的认知能力。不幸地是，只要你一超过这个“节制”的量，你的记忆力和反应时间都在衰退。在同一项研究中，那些一天喝四次甚至更多就的人境况最糟。
                </p>
                <p>
                  同样有害的还有现在普遍存在的周末“狂饮作乐”。老鼠身上的研究发现，喝下大量酒精的老鼠大脑海马中的新细胞立刻减少，事实上狂饮后的一个月里一个新细胞也没有。这表明酒精不仅损伤老鼠的大脑，还会阻止它们自我修复。对人类来说，这意味着你不要指望很快通过门萨入门考试了。
                </p>
                <p>
                  花时间玩儿。找时间玩儿。打牌。打电视游戏。下棋。看暗夜来电。参加拔河比赛。你玩什么没关系。就是得玩儿！这对你的精神和大脑都有好处。这给了你的大脑进行战略思考的机会，同时让它能够保持运行。
                </p>
                <p className="b">17、在记忆旁睡觉</p>
                <p>
                  预习关键信息，然后睡觉，有助于提高20%到30%的记忆力。如果这种信息令你昏昏欲睡，你会很容易伴着它入睡。但如果你的思维让你保持清醒，就把所有的都写下来，有时候通过此途径把它们“赶出大脑”你就可以睡着了（所以身旁要常备纸和笔）。
                </p>
                <p className="b"> 18、全神贯注</p>
                <p>
                  全神贯注可以提高脑力。这是显而易见的，但是那些干扰注意力的因素就并不总是那麽显而易见。学会注意你何时走神了。通常原因都是潜意识里的。例如，你需要打个电话，这可能干扰你整个早上，暗中破坏了你清晰思考的能力，即是你并没有意识到什么在扰乱你。
                </p>
                <p>
                  养成停下来问问“我的大脑现在在想什么”的习惯。确定它，并处理它。在刚才的例子中，你可以打个电话，或者把它放到明天的安排中，这样的大脑就可以轻松地放下它了。这让你有一个更放松的状态，可以更好地思考。现在就用这个方法提升脑力吧！
                </p>
                <p className="b">19、充满激情的活动</p>
                <p>
                  没有全身心投入，你做不了伟大的工作。当人们在学习和创造中成长时，他们都会全力以赴，投入比127%还多的精力在工作中。愉悦自己或者你愉悦世界。还记得，当你是个孩子时，你喜欢干什么，就把所有精力放在那上面。这是你通向天才，通向天赋的途径。达芬奇，爱迪生，爱因斯坦和毕加索都热爱活动热爱探索。
                </p>
                <p className="b">20、意识的循环</p>
                <p>
                  你的意识在一天中时强时弱。大部分情况，每九十分钟就有三十分钟的弱意识期。观察你自己来认识这种周期。如果你学会了认识并追踪你的精神状态，你可以在你的思维“最清醒”的时候，把精力集中在最重要的思维活动上。为了对一个问题有一种创造性的领悟，从反向去思考。这种工作要在当你处于昏昏欲睡的状态，当你的意识和思维都在下降时去做。
                </p>
                <p className="b">21、学习一些新事物</p>
                <p>
                  这一项可能是显然成立的。是的，当我们让大脑去学习一些新事物时，就是在开发大脑巨大潜力。也许你有一个关于工作与休闲的特定论题想研究。这很不错。
                  去研究它吧。如果你的脑中现在没有一个主题，就每天学一个新单词。主动词汇与智力有着强大的联系。当你的词汇表中有了新单词后，大脑就可以用新的方式思考两种思想之间的更细微的差别。让你的大脑不断学习吧。这是让你的大脑重新充满能量的最好方式。
                </p>
                <p className="b">22、为了读而写</p>
                <p>
                  我是写日记来捕捉思考和想法的忠实拥护者。为自己写作肯定有巨大价值。我不断的发现，在写些需要阅读的东西时我的大脑被很好地激活了。写作的最大益处就是它可以扩充你的脑容量。找到为读而写的方式，比如写些给你的朋友阅读的东西，写些自己童年的故事，开一个自己的博客，无论哪种方式，为读而写吧！
                </p>
                <p className="b">23、尝试芳香疗法激活你的大脑</p>
                <p>
                  一天，在一个会议上听一些没完没了的演讲时，我睡着了，一阵古龙水的柠檬香味传来让我的大脑突然活跃起来。我立刻感到振奋起来，发现把精神集中到主持人身上要容易多了。我发现芳香疗法确实有用，我曾为了提神或放松使用过。
                </p>
                <p>
                  可以充当情绪兴奋剂的味道有薄荷，柏杨和柠檬。起到舒缓作用的味道有：依兰，天竺葵和玫瑰。滴几滴精油在浴缸中或者在扩散器中，都可以起到作用。你也可以在一个棉球或手帕中滴一两滴，然后吸入这种味道。对于办公地点使用的一个警告：先确保没有人对你使用的精油过敏。
                </p>
                <p className="b">24、药物提升脑力</p>
                <p>
                  咖啡或其它含咖啡因的饮料可以帮助学生在考试中持续获得高分。由于咖啡因限制了大脑中的血管，它们对脑力的长期影响还不是很清楚。所以尝试着用银杏茶和积雪草茶代替咖啡。银杏提取物被证明有提升大脑血流量，改善注意力的作用。
                </p>
                <p className="b">25、建立对大脑的信任</p>
                <p>
                  让你的身边充满各个领域能激励你并激发你创造力的有抱负的人们。广泛阅读来自各个领域的杂志。让人，地方和事情联系起来，探索新的机遇，为你的问题寻找答案。
                </p>
              </div>
            </div>
          </div>
        )}
        <FeedBack orderNo={orderId} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm }}
      />
    </Layout>
  )
}

export default PageIndex
