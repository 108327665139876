import { IQA } from './types'

const qaList: Array<IQA> = [
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/1/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/2/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/3/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/4/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/5/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/6/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/7/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/8/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/9/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/10/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/11/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/12/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/13/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/14/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/15/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/16/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/17/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/18/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/19/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/20/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/21/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/22/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/23/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/24/6.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/25/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/26/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/27/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/28/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/29/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/30/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/31/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/32/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/33/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/34/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/35/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/36/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/37/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/38/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/39/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/40/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/41/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/42/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/43/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/44/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/45/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/46/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/47/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/48/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/49/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/50/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/51/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/52/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/53/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/54/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/55/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/56/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/57/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/58/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/59/8.png',
    ],
  },
  {
    title: '请选择一个最适合填入空缺中的图形',
    question: 'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/0.png',
    answers: [
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/1.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/2.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/3.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/4.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/5.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/6.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/7.png',
      'https://lmdt-open.oss-cn-shanghai.aliyuncs.com/iqt/202208/60/8.png',
    ],
  },
]

const rightAnswer = [
  4, 5, 1, 2, 6, 3, 6, 2, 1, 3, 4, 5, 2, 6, 1, 2, 1, 3, 5, 6, 4, 3, 4, 5, 8, 2, 3, 8, 7, 4, 5, 1, 7,
  6, 1, 2, 3, 4, 3, 7, 8, 6, 5, 4, 1, 2, 5, 6, 7, 6, 8, 2, 1, 5, 1, 6, 3, 2, 4, 5,
]

export { qaList, rightAnswer }
